export const ALPHA_NUMERIC_ERROR_MESSAGE =
  "Enter alpha numeric charachters only";
export const ALPHA_NUMERIC_WITH_SPACE_ERROR_MESSAGE =
  "Enter alpha numeric with space only";
export const ALPHA_NUMERIC_WITH_SPACE_BRACKETS_HYPHEN_ERROR_MESSAGE =
  "Enter alpha numeric with valid symbols only";
export const ALPHA_NUMERIC_WITH_SPACE_BRACKETS_HYPHEN_AND_SYMBOLS_ERROR_MESSAGE =
  "Enter alpha numeric with valid symbols only";
export const ALPHA_NUMERIC_WITH_SPACE_DOT_HYPHEN_ERROR_MESSAGE =
  "Enter alpha numeric with valid symbols only";
export const ALPHA_NUMERIC_WITH_SPACE_UNDERSCORE_HYPHEN_AND_SYMBOLS_ERROR_MESSAGE =
  "Enter alpha numeric with valid symbols only";
export const ALPHA_NUMERIC_WITH_SPACE_APOSTROPHE =
  "Enter alpha numeric with space and apostrophe only";
export const ALPHA_NUMERIC_WITH_SYMBOLS_ERROR_MESSAGE =
  "Enter alpha numeric with symbols only";
export const ALPHA_NUMERIC_WITH_UNDERSCORE_ERROR_MESSAGE =
  "Enter alpha numeric with underscore only";
export const ADDRESS_ERROR_MESSAGE = "Enter valid address";
export const DATE_WITH_SLASH_ERROR_MESSAGE = "Enter valid date";
export const DISABLE_HTML_INPUT_ERROR_MESSAGE = "Angular brackets not allowed";
export const EMAIL_ERROR_MESSAGE = "Fill in a valid email address";
export const PARTIAL_EMAIL_ERROR_MESSAGE = "Please enter a valid email address";
export const GENERIC_ERROR_MESSAGE =
  "Fill in or correct the fields highlighted on the form";
export const NUMBER_ERROR_MESSAGE = "Please enter a valid number";
export const ORGANIZATION_NAME_ERROR_MESSAGE =
  "Please enter a valid company name";
export const PASSWORD_ERROR_MESSAGE = "Please enter a valid password";
export const STANDARDPASSWORD_ERROR_MESSAGE =
  "Entered password does not meet the requirement";
export const USERNAME_ERROR_MESSAGE = "Please enter valid characters";
export const NICKNAME_ERROR_MESSAGE = "Please enter valid characters";

export const PERMISSION_DENIED = "You do not have permission to view this page";

// billing error messages
export const FAILED_TO_IMPORT_BILLING_STATEMENTS =
  "Could not import statements. Please try again";
export const FAILED_TO_IMPORT_SAMPLE_STATEMENTS =
  "Could not download sample template. Please try again";

// traveller error messages
export const FAILED_TO_ADD_TRAVELLER =
  "Could not add the traveller. Please try again";
export const FAILED_TO_DELETE_TRAVELLER =
  "Could not delete the traveller. Please try again";
export const FAILED_TO_EXPORT_TRAVELLER =
  "Could not export travellers. Please try again";

export const FAILED_TO_GET_IMPORT_ERROR_DETAILS =
  "Could not download error details. Please try again";
export const FAILED_TO_IMPORT_TRAVELLER =
  "Could not import travellers. Please try again";
export const FAILED_TO_IMPORT_SAMPLE_TRAVELLER =
  "Could not download sample template. Please try again";

export const FAILED_TO_UPDATE_TRAVELLER =
  "Could not update the traveller. Please try again";
export const FAILED_TO_ENABLE_TRAVELLER =
  "Could not enable traveller. Please try again";
export const FAILED_TO_DISABLE_TRAVELLER =
  "Could not disable traveller. Please try again";
export const FAILED_TO_DELETE_USER =
  "Could not delete user. Please try again";

// myaccount error message
export const FAILED_TO_UPDATE_COMPANY_DETAILS =
  "Could not save your company details, Please try again";
export const FAILED_TO_RESET_PASSWORD =
  "Could not reset password. Please try again";
export const FAILED_TO_DELETE_CARD =
  "Error! Could not delete your card, please try again";
export const FAILED_TO_UPDATE_CARD =
  "Error! Could not update your card details, please try again";
export const FAILED_TP_SAVE_CARD =
  "Could not save your card details, please try again";
export const FAILED_TO_UPGRADE_PLAN =
  "Error! Could not upgrade your plan, please try again";
export const FAILED_TO_CHANGE_PLAN =
  "Could not process your request at the moment. Please try again";
export const FAILED_TO_DOWNGRADE_PLAN =
  "Unable to change your plan. Please try again";
export const FAILED_PAYMENT = "Your payment was unsuccessful, please try again";
export const FAILED_TO_GET_INVOICE_STATEMENT =
  "No invoice and statement details found";
export const FAILED_TO_DOWNLOAD_INVOICE_STATEMENT =
  "Could not process your request at the moment. Please try again";

// policies error message
export const FAILED_TO_ADD_POLICY =
  "Could not add the policy. Please try again";
export const FAILED_TO_UPDATE_POLICY =
  "Could not update the policy. Please try again";
export const FAILED_TO_ENABLE_POLICY =
  "Could not enable policy. Please try again";
export const FAILED_TO_DISABLE_POLICY =
  "Could not disable policy. Please try again";
export const FAILED_TO_DELETE_POLICY =
  "Could not delete the policy. Please try again";

// Approval error messages
export const FAILED_APPROVAL_API =
  "Uh oh. That doesn't seem to work. Try again or refresh this page";
export const APPROVAL_TRIP_EXPIRED =
  "This trip was not approved in time. Request a new approval.";
export const APPROVAL_TRIP_FAILED =
  "One or more bookings are no longer available. Request a new approval or contact us for assistance.";
export const APPROVAL_TRIP_OTHER_STATUS = "This request has already been {0}";
export const APPROVAL_NO_ACTIVITY_ID =
  "Could not process your request at the moment. Contact your supervisor for assistance";
export const FAILED_TO_DOWNLOAD_ATTACHMENT =
  "Could not process your request at the moment. Please try again later";
export const FAILED_TO_INITIATE_APPROVAL =
  "Something went wrong - the system could not send the trip for approval. Please try again";
export const APPROVAL_INITIALISATION_SUCCESSFUL =
  "This trip will now go to your approver(s) for review and you will receive updates by email";
// generic api error messages

export const FAILED_API =
  "Something went wrong. Try again or refresh this page.";

export const NO_INTERNET_CONNECTION = "You are not connected to the internet";

// #region request form
export const FAILED_TO_ADD_REQUEST =
  "Could not add the request. Please try again";
export const FAILED_TO_UPDATE_REQUEST =
  "Could not update the request. Please try again";
export const FAILED_TO_UPDATE_REQUEST_STATUS =
  "Request status couldn’t be updated. Kindly try again in some time";
export const SAME_REQUEST_STATUS_UPDATE =
  "Request status has not been changed.";
export const REQUEST_ATTACH_DOCUMENTS =
  "For the selected Travel Purpose, attaching document(s) are mandatory";

export const TAG_VALUES_EMPTY_ERROR =
  "The tag value relationship does not exist";

export const FAILED_TO_DELETE_REQUEST_FORM =
  "Could not delete the request. Please try again";

export const ORAGANISATION_IS_REQUIRED_DRAFT =
  "Please specify Organization before saving the draft";
// #endregion

// #region  trip page messages
export const NO_PERMISSION_TO_VIEW_CONVERSATION =
  "You do not have permission to access the conversation related to this trip.";

export const NO_PERMISSION_TO_PURCHASE_TRIP =
  "You are not allowed to purchase this trip. Kindly reach out to Musafir staff for assistance.";

export const FAILED_TO_AUTO_CREATE_TRIP =
  "Something went wrong. Please try again, else wait for some time before trying again.";
// #endregion

// #region conversation messages
export const FAILED_TO_CREATE_CONVO =
  "Something went wrong. The system could not add your comment. Please try again.";

export const FAILED_TO_ADD_MESSAGE =
  "Something went wrong. The system could not add your comment. Please try again.";

export const FAILED_TO_FETCH_CONVO =
  "Something went wrong. The system could not load conversations on this page. Try refreshing this page.";

export const FAILED_TO_FETCH_CONVO_GROUP =
  "Something went wrong. The system could not open this area. Please try again.";

export const FAILED_TO_UPLOAD_DOCUMENTS =
  "The system could not attach selected document(s). Kindly try again.";

export const FAILED_TO_DELETE_DOCUMENTS =
  "The system could not remove selected document(s). Kindly try again.";
// #endregion

// #region expense form
export const FAILED_TO_DELETE_EXPENSE_FORM =
  "Could not delete the request. Please try again";
// #region expense management

export const FAILED_TO_UPDATE_EXPENSE =
  "Could not update the folio. Please try again";

// #endregion

export const INPUT_ERROR_MESSAGE = "Please enter valid input";
// #region visa dashboard
export const NO_DATA_FOUND = "No Records found";
export const DOWNLOAD_ERROR =
  "Could not download the file at the moment. Please try again";
// #region visa dashboard
