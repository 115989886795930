export const API_CALL_TYPES = {
  DELETE: "delete",
  DOWNLOAD: "download",
  GET: "get",
  POST: "post",
  PUT: "put",
  UPLOAD: "upload",
};

// #region trips api's
const CAN_INITIATE_APPROVAL = "plus/approval/canInitialize/";
const CONFIRM_TRIP = "plus/tripAction/confirm";
const CREATE_TRIP_AUTO = "plus/trip/add";
const GET_TRIP_DETAILS = "plus/trip/";
const LOCK_TRIP = "plus/tripAction/lockUnlock?SystemReference=";
const TRIPS_ADD_FILTER = "plus/trips/addFilter";
const TRIPS_DELETE_FILTER = "plus/trips/deleteFilter";
const TRIPS_DICARD = "plus/tripAction/discard";
const TRIPS_LISTING = "plus/trips";
const TRIPS_GETBYID = "plus/trips/getTripById";
const TRIPS_GETBYID_SYSTEMREFERENCE = "plus/trip";
const TRIPS_UPDATE_FILTER = "plus/trips/updateFilter";
const TRIPS_REQUEST_REFUND = "plus/tripAction/requestRefund?systemReference=";
const TRIPS_SET_OFF = "plus/tripAction/setOff?systemReference=";
const TRIP_FILTER_RESOLUTION_DATA = "plus/trips/getFilterResolutionDetails";
// cancellation booking api's
const GET_BOOKING_DETAILS = "plus/tripDetails/";
const TRIPS_REFUND_DETAILS = "plus/refund/";
const GENERATE_FDT_FOR_TRIP_WITH_OFFER =
  "plus/GenerateFDTicketOfferCodeApplied";
const GENERATE_FDT_ONLINE_CANCELLATION_NOT_AVAILABLE =
  "plus/GenerateFDTicketOnlineCancellationNotAvailable";
const TRIP_VERIFY_AND_CANCEL = "plus/verifyAndCancel";
const GET_CHANGE_BOOKING_DETAILS = "plus/change/tripDetails/";
const GENERATE_FDT_CHANGE_TRIP = "plus/change/createFDTicket";
const GET_APPROVAL_TRIP_DETAILS = `${GET_TRIP_DETAILS}approval/`;

export const TRIPS = {
  ADD_FILTER: TRIPS_ADD_FILTER,
  CAN_INITIATE_APPROVAL,
  CONFIRM_TRIP,
  CREATE_TRIP_AUTO,
  DISCARD: TRIPS_DICARD,
  DELETE_FILTER: TRIPS_DELETE_FILTER,
  FILTER_RESOLUTION_DATA: TRIP_FILTER_RESOLUTION_DATA,
  GENERATE_FDT_FOR_TRIP_WITH_OFFER,
  GENERATE_FDT_ONLINE_CANCELLATION_NOT_AVAILABLE,
  GET_APPROVAL_TRIP_DETAILS,
  GET_BOOKING_DETAILS,
  GET_CHANGE_BOOKING_DETAILS,
  GENERATE_FDT_CHANGE_TRIP,
  GETBYID: TRIPS_GETBYID,
  GETBYID_SYSTEMREFERENCE: TRIPS_GETBYID_SYSTEMREFERENCE,
  GET_TRIP_DETAILS,
  LOCK_TRIP,
  LISTING: TRIPS_LISTING,
  REQUEST_REFUND: TRIPS_REQUEST_REFUND,
  SET_OFF: TRIPS_SET_OFF,
  TRIPS_REFUND_DETAILS,
  TRIP_VERIFY_AND_CANCEL,
  UPDATE_FILTER: TRIPS_UPDATE_FILTER,
};
// #endregion

// #region myaccount api's
const CARD_DETAILS = "plus/cardDetails";
const REGISTER_PAYMENT = "payment/add";
const PAYMENT_RESPONSE = "payment/response";
const INITIALIZE_PAYMENT = "plus/payment/initialize";
const PLAN_INVOICES = "plus/organizationPricingPlan/invoices";
const PLAN_INVOICE_STATEMENT = "plus/organizationPricingPlan/invoices";
const DOWNLOAD_PLAN_INVOICE = "invoice/";
const DOWNLOAD_PLAN_STATEMENT = "plus/organizationPricingPlan/statement/";

export const MY_ACCOUNT = {
  CARD_DETAILS,
  DOWNLOAD_PLAN_INVOICE,
  DOWNLOAD_PLAN_STATEMENT,
  INITIALIZE_PAYMENT,
  PAYMENT_RESPONSE,
  PLAN_INVOICES,
  REGISTER_PAYMENT,
  PLAN_INVOICE_STATEMENT,
};
// #endregion

// #region organization api's
const PLUS_ORGANIZATION = "plus/organization";
const PLUS_ORGANIZATION_DETAILS = `${PLUS_ORGANIZATION}/details`;
const INITIALIZE_ORGANIZATION = "plus/organization/init";
const CHANGE_ORGANIZATION_PLAN = "plus/organizationPricingPlan/changePlan/";
const ORGANIZATION_USERS = "plus/organization/user/getByKeyword/[keyword]";

export const ORGANIZATION = {
  CHANGE_ORGANIZATION_PLAN,
  INITIALIZE_ORGANIZATION,
  ORGANIZATION_USERS,
  PLUS_ORGANIZATION,
  PLUS_ORGANIZATION_DETAILS,
};
// #endregion

// #region hotel api's
const FETCH_HOTELS = "tripsearch/results/v1/";
const INITIALIZE_HOTEL_SEARCH = "tripsearch/init/v1";
const FETCH_HOTEL_DETAILS = "hotelsearch/hotel/[hotelId]/details/v1";
const FETCH_HOTEL_PACKAGES = "hotelsearch/hotel/[hotelId]/packages/v1";
const SELECT_HOTEL_PACKAGE =
  "hotelsearch/[trackingId]/hotel/[hotelId]/packages/select/v1";

const FETCH_LOCATION_DETAILS = "location/[locationId]";
const HOTEL_QUOTATION_EMAIL = "plus/quotation/saveOrSend";

export const HOTEL = {
  FETCH_HOTELS,
  INITIALIZE_HOTEL_SEARCH,
  FETCH_HOTEL_DETAILS,
  FETCH_HOTEL_PACKAGES,
  FETCH_LOCATION_DETAILS,
  SELECT_HOTEL_PACKAGE,
  HOTEL_QUOTATION_EMAIL,
};

// #endregion

// #region flight api's
const INITIALIZE_FLIGHT_SEARCH = "flight/init";
const FETCH_FLIGHTS = "flight/results";
const BOOK_FLIGHT = "flight/selectFlight";
const FLIGHT_FARE = "flight/fare";
const FLIGHT_QUOTATION_EMAIL = "plus/quotation/saveOrSend";
const FLIGHT_FARE_ALL = "flight/fareruleslite";

export const FLIGHT = {
  INITIALIZE_FLIGHT_SEARCH,
  FETCH_FLIGHTS,
  FLIGHT_FARE,
  BOOK_FLIGHT,
  FLIGHT_QUOTATION_EMAIL,
  FLIGHT_FARE_ALL,
};

// #region location api's
const GET_LOCATIONS = "location/resolve/";

export const LOCATION = {
  GET_LOCATIONS,
};
// #endregion

// #region request form api's
const ADD_REQUEST = "plus/request/add";
const ADD_REQUEST_DRAFT = "plus/request/addDraft";
const DELETE_REQUEST_DRAFT = "plus/request/deleteDraft/";
const UPDATE_REQUEST = "plus/request/update/";
const UPDATE_REQUEST_DRAFT = "plus/request/updateDraft/";
const SUBMIT_REQUEST_DRAFT = "plus/request/submitDraft/";
const GET_REQUEST_DETAILS_BY_ID = "";
const INITAIALIZE_REQUEST_FORM = "init";
const LISTING_REQUEST_FORM = "plus/request";
const UPDATE_REQUEST_STATUS = "/plus/request/updateStatus/";
const FETCH_REQUEST_FORM_DETAILS = "plus/request/";
export const REQUEST_FORM = {
  ADD_REQUEST,
  ADD_REQUEST_DRAFT,
  DELETE_REQUEST_DRAFT,
  UPDATE_REQUEST,
  UPDATE_REQUEST_DRAFT,
  SUBMIT_REQUEST_DRAFT,
  GET_REQUEST_DETAILS_BY_ID,
  INITAIALIZE_REQUEST_FORM,
  LISTING_REQUEST_FORM,
  UPDATE_REQUEST_STATUS,
  FETCH_REQUEST_FORM_DETAILS,
};

// #endregion

// #region Conversation API
const ADD_CONVERSATION =
  "plus/convoGateway/[contextType]/[contextReferenceId]/message/add";
const CREATE_NEW_CONVERSATION = "plus/convoGateway/add";
const GET_CONVERSATION_BY_REFERENCE_ID = "plus/convoGateway/conversation";
const GET_CONVERSATION_GROUP_BY_REFERENCE_ID =
  "plus/convoGateway/conversationGroup";
const GET_CONVERSATION_GROUP_META_BY_REFERENCE_ID =
  "plus/convoGateway/conversationGroup/lite";
const ADD_SIBLING = "plus/convoGateway/sibling/add";

export const CONVERSATION = {
  ADD_CONVERSATION,
  CREATE_NEW_CONVERSATION,
  ADD_SIBLING,
  GET_CONVERSATION_BY_REFERENCE_ID,
  GET_CONVERSATION_GROUP_BY_REFERENCE_ID,
  GET_CONVERSATION_GROUP_META_BY_REFERENCE_ID,
};

// #endregion

// #region attachments

const APP_ATTACHMENT = "plus/uploadDocument";
const CONVERSATION_ATTACHMENT = "convo/attachment";
const DOCUMENT_UPLOAD = "document";

export const ATTACHMENT = {
  APP_ATTACHMENT,
  CONVERSATION_ATTACHMENT,
  DOCUMENT_UPLOAD,
};

// #endregion

// #region expense management

const EXPENSES_LISTING = "plus/expense";
const INITAIALIZE_EXPENSE_FORM = "init";
const ADD_FOLIO_REQUEST = "plus/expense/upsert";
const FETCH_EXPENSE_FORM_DETAILS = "plus/expense/";
const DELETE_EXPENSE_DRAFT = "plus/expense/delete/";
const EXPENSES_APPROVALS = "plus/expenseApproval/";
const EXPENSES_APPROVALS_ACTION = "plus/expenseApproval/action/";
const EXPENSES_REIMBURSE = "plus/expenseApproval/reimburse";
export const EXPENSE_MANAGEMENT = {
  ADD_FOLIO_REQUEST,
  EXPENSES_LISTING,
  INITAIALIZE_EXPENSE_FORM,
  FETCH_EXPENSE_FORM_DETAILS,
  DELETE_EXPENSE_DRAFT,
  EXPENSES_APPROVALS,
  EXPENSES_APPROVALS_ACTION,
  EXPENSES_REIMBURSE,
};

// #endregion expense management

// #region visa dashboard
export const VISA_DASHBOARD = "VisaTracking/VisaCustomerTracker";
export const FILE_DOWNLOAD_LINK = "DocumentDownload/DocumentDownloadS3";
// #endregion visa dashboard

//#region quotation
export const GET_QUOTATIONS = "plus/quotation/GetQuotations";
export const QUOTATION_DETAILS = "/plus/quotation/GetDetails";
