import {
  AGENT_SIGNIN,
  APPROVALS_PAGE,
  BILLING_PAGE,
  CREATEPASSWORD,
  CHANGEPASSWORD,
  EXPENSE_MANAGEMENT,
  FORGOTPASSWORD,
  SIGNIN,
  SIGNUP,
  TRAVELLER,
  TAGS,
  TRIPS,
  MY_ACCOUNT_PAGE,
  POLICIES_PAGE,
  COMPONENTS_PAGE,
  SLASH,
  SUSPENDED_ACCOUNT,
  CHANGE_PLAN,
  RENEW_PLAN,
  PLAN_PAY_NOW,
  REQUEST_FORM,
  VISA,
  QUOTATION,
} from "./Routes";

import unauthImg from "../assets/images/unauth-page-img.png";
import errorImg from "../assets/images/error-img.png";

export const ALLOWED_COMMON_PATHS = [
  TRAVELLER,
  TRIPS,
  MY_ACCOUNT_PAGE,
  VISA,
  QUOTATION,
];
export const ALLOWED_PATH_NOT_LOGGED_IN_USER = [
  AGENT_SIGNIN,
  CREATEPASSWORD,
  CHANGEPASSWORD,
  FORGOTPASSWORD,
  SIGNIN,
  SIGNUP,
  SLASH,
];
export const ALLOWED_PATH_LOGGED_IN_USER = [
  APPROVALS_PAGE,
  BILLING_PAGE,
  EXPENSE_MANAGEMENT,
  TRAVELLER,
  TAGS,
  MY_ACCOUNT_PAGE,
  POLICIES_PAGE,
  TRIPS,
  COMPONENTS_PAGE,
  SUSPENDED_ACCOUNT,
  REQUEST_FORM,
  VISA,
  QUOTATION,
];

export const All_SHORTLISTED_HOTELS_KEY = "allShortListedHotels";

export const SASS_BILLIN_ACTION_URLS = [CHANGE_PLAN, RENEW_PLAN, PLAN_PAY_NOW];

export const CREATE_PASSWORD_TYPE = 2;
export const COUNTRY_LIST = [
  { value: "2", label: "India", plansEnabled: false, contact: "022 4164 2214" },
  { value: "4", label: "KSA", plansEnabled: false, contact: "+9716 516 6666" },
  { value: "3", label: "Qatar", plansEnabled: false, contact: "04497  9999" },
  { value: "1", label: "UAE", plansEnabled: false, contact: "600 544 405" },
];

export const PLAN_IDS = {
  START_UP: 1,
  PROFESSIONAL: 2,
  ENTERPRISE: 3,
  NEW_START_UP: 4,
  TEAM: 5,
  ESSENTIAL: 6,
  NEW_PROFESSIONAL: 7,
  NEW_ENTERPRISE: 8,
  NEW_ADVANCED_ENTERPRISE: 9,
};

// Plan statuses of organization.
export const PLAN_STATUS = {
  NEW: 1,
  PAID: 2,
  GRACE: 3,
  TRIAL: 4,
  DUE: 5,
};

export const DEBOUNCE_VALUE = 400;
export const SEARCH_DEBOUNCE_VALUE = 1000;
export const DISABLE_MARKET_CHANGE = [SIGNUP];

export const SHOW_ERROR_IN = {
  NONE: 0,
  SCREEN: 1,
  DETAIL: 2,
};

export const ERROR_TYPE = {
  GENERIC: 0,
  BAD_REQUEST: 400,
  UNAUTHENTICATED: 401,
  UNAUTHORIZED: 403,
  PAGE_NOT_FOUND: 404,
  SUSPENDED: 470,
  INTERNAL_SERVER_ERROR: 500,
  CUSTOM: -1,
};

export const API_RESPONSE_CODE = {
  SUCCESS: 200,
  MULTIPLE_RESPONSE_CHOICES: 300,
  ...ERROR_TYPE,
};

export const BROWSER = {
  EDGE: 1,
  IE: 2,
  CHROME: 3,
  FIREFOX: 4,
  SAFARI: 5,
};

export const ERROR_PAYLOAD = {
  0: {
    imageSrc: errorImg,
    route: "common",
    lKey: "DescriptionInternalServerError",
  },
  400: {
    imageSrc: errorImg,
    route: "common",
    lKey: "DescriptionInternalServerError",
  },
  403: {
    imageSrc: unauthImg,
    route: "common",
    lKey: "DescriptionUnauthorised",
  },
  404: {
    imageSrc: errorImg,
    route: "common",
    lKey: "DescriptionPageNotFound",
  },
  500: {
    imageSrc: errorImg,
    route: "common",
    lKey: "DescriptionInternalServerError",
  },
};

export const HEADER_TYPE = {
  LINK: 0,
  BUTTON: 1,
  MENU: 2,
  ADD_ICON: 3,
  FILTER_ICON: 4,
  SORT_ICON: 5,
  EXPORT_SVG: 6,
  IMPORT_SERVICE_SVG: 7,
  EDIT_ICON: 8,
  LOCK_ICON: 9,
  UNLOCK_ICON: 10,
  EMAIL_ICON: 11,
  DOWNLOAD_ICON: 12,
  CONSOLE_ICON: 13,
};

export const LIST_PAGE_SIZE = 100;

export const MY_ACCOUNT = "MyAccount";
export const MY_ACCOUNT_TYPE = 2;

export const MINIMUM_PASSWORD_LENGTH = 8;

export const LocationType = {
  Undefined: 0,
  Airport: 1,
  City: 2,
  Country: 3,
  Region: 4,
};

export const TAG_TYPE = {
  TRAVELLER_TAG_TYPE: 1,
  TRIP_TAG_TYPE: 2,
  TRIP_TRAVELLER_TAG_TYPE: 4,
};

export const TAGS_OBJECT_TYPE = {
  TRIPS: 2,
  TRAVELLER: 11,
};

export const PAGE_SIZE = 8;
export const PURSHASE_PAYMENT_METHOD_ID = 3;

export const RESET_PASSWORD_TYPE = 1;
export const RESOLUTION_BUBBLE_DEFAULT_PAGE_SIZE = 8;
export const SCROLL_ON_CHANGE_TIMEOUT_IN_MILLISECONDS = 500;
export const SCROLL_TIMEOUT_IN_MILLISECONDS = 1000;
export const RESOLUTION_BUBBLE_MAXLENGTH = 100;

export const WINDOW_SIZES = {
  isSmall: "isSmall",
  isMedium: "isMedium",
  isLarge: "isLarge",
};

export const MAX_HEADER_ICONS = 4; // app bar header icons

export const NUMBER_OF_HEADER_ICONS_BASED_ON_WINDOW_SIZE = {
  isSmall: 2,
  isMedium: 2,
  isLarge: 3,
};
export const NUMBER_OF_DETAIL_HEADER_ICONS_BASED_ON_WINDOW_SIZE = {
  isSmall: 2,
  isMedium: 2,
  isLarge: 5,
};

export const REACT_APP_PAGE = "/app/";

// market profile id {UAE : 1, INDIA: 2, QATAR: 3, KSA: 4}
export const CONTACT_NUMBER_INTERNATIONAL_CODE = {
  1: "971",
  2: "91",
  3: "974",
  4: "966",
};

export const MARKET_PROFILE_COUNTRY_CODE = {
  UAE: "AE",
  INDIA: "IN",
  QATAR: "QA",
  KSA: "SA",
};

export const CONTACT_NUMBER_MARKETWISE_PLACEHOLDERS = {
  1: "xx-xxxxxxx",
  2: "xxxxx-xxxxx",
  3: "xxxxxxxx",
  4: "xx-xxxxxxx",
};

export const PRICE_UNIT = { 1: "AED", 2: "Rs", 3: "QAR", 4: "SAR" };
export const TAX_TYPE = { 1: "VAT", 2: "GST", 3: "VAT", 4: "VAT" };

export const INDICATOR_COLOR = {
  GREEN: 1,
  YELLOWCIRCLE: 2,
  WHITE: 3,
  GRAY: 4,
  RED: 5,
  YELLOW: 6,
  BLUE: 7,
};

export const TEXT_COLOR = {
  DEFAULT: 0,
  RED: 1,
  GREEN: 2,
};

export const BADGE_COLOR = {
  GREEN: 1,
  BLUE: 2,
  WHITE: 3,
  GRAY: 4,
  RED: 5,
  YELLOW: 6,
  GHOSTGREEN: 7,
  GHOSTGRAY: 8,
  DARKGRAY: 9,
  DARKBLUE: 10,
  GHOSTBLUE: 11,
  GHOSTRED: 12,
  GHOSTORANGE: 13,
  GHOSTGREENCOLOR: 14,
  LINKBLUE: 15,
  LIGHTBLUE: 16,
};

export const LISTING_BADGE_COLOR = {
  GREEN: 1,
  BLUE: 2,
  WHITE: 3,
  GRAY: 4,
};
export const TRIP_STATUS = {
  0: BADGE_COLOR.GHOSTGREENCOLOR, // Approved
  1: BADGE_COLOR.GHOSTBLUE, // Await approval
  2: BADGE_COLOR.GHOSTRED, // Rejected
  3: BADGE_COLOR.GHOSTGRAY, // Expired
  4: BADGE_COLOR.GHOSTRED, // Removed
};

export const POLICIES_STATUS_BY_NAME = {
  Pass: {
    displayLabel: "In policy",
    color: INDICATOR_COLOR.GREEN,
    label: "pass",
  },
  Fail: {
    displayLabel: "Out of policy",
    color: INDICATOR_COLOR.RED,
    label: "fail",
  },
  Partial: {
    displayLabel: "Partial policy",
    color: INDICATOR_COLOR.YELLOW,
    label: "partial",
  },
  PassWithConditions: {
    displayLabel: "In policy",
    color: INDICATOR_COLOR.GREEN,
    label: "pass",
  },
};

export const POLICIES_STATUS = {
  1: {
    displayLabel: "In policy",
    color: INDICATOR_COLOR.GREEN,
    label: "pass",
  },
  2: {
    displayLabel: "Out of policy",
    color: INDICATOR_COLOR.RED,
    label: "fail",
  },
  3: {
    displayLabel: "Partial policy",
    color: INDICATOR_COLOR.YELLOW,
    label: "partial",
  },
};

export const BOOKING_SOURCE_ABBREVIATIONS = {
  Website: "WB",
  Meta: "MT",
  MobileApplication: "MA",
  MobileIosApp: "MA",
  MobileAndroidApp: "MA",
};

export const STEPPER_STATUS = {
  ACTIVE: "active",
  COMPLETED: "completed",
  INACTIVE: "inactive",
};

export const BOOKING_FILE_STATUS = [
  {
    status: "New",
    color: INDICATOR_COLOR.WHITE,
    hideAmountDue: true,
  },
  {
    status: "Quotation in progress",
    color: INDICATOR_COLOR.WHITE,
  },
  {
    status: "Unable to quote",
    color: INDICATOR_COLOR.WHITE,
  },
  {
    status: "Partially quoted",
    color: INDICATOR_COLOR.WHITE,
  },
  {
    status: "Quoted",
    color: INDICATOR_COLOR.WHITE,
  },
  {
    status: "Reservation in progress",
    color: INDICATOR_COLOR.WHITE,
  },
  {
    status: "Unable to reserve",
    color: INDICATOR_COLOR.RED,
  },
  {
    status: "Partially reserved",
    color: INDICATOR_COLOR.RED,
  },
  {
    status: "Reserved",
    // color: INDICATOR_COLOR.YELLOWCIRCLE
    color: INDICATOR_COLOR.YELLOW,
  },
  {
    status: "Discarding in progress",
    color: INDICATOR_COLOR.WHITE,
  },
  {
    status: "Unable to discard",
    color: INDICATOR_COLOR.WHITE,
  },
  {
    status: "Partially discarded",
    color: INDICATOR_COLOR.WHITE,
  },
  {
    status: "Discarded",
    color: INDICATOR_COLOR.WHITE,
    hideAmountDue: true,
  },
  {
    status: "Ticketing in progress",
    color: INDICATOR_COLOR.WHITE,
  },
  {
    status: "Unable to ticket",
    color: INDICATOR_COLOR.RED,
  },
  {
    status: "Partially ticketed",
    color: INDICATOR_COLOR.RED,
  },
  {
    status: "Ticketed",
    color: INDICATOR_COLOR.GREEN,
  },
  {
    status: "Cancellation in progress",
    color: INDICATOR_COLOR.WHITE,
  },
  {
    status: "Unable to cancel",
    color: INDICATOR_COLOR.RED,
  },
  {
    status: "Partially cancelled",
    color: INDICATOR_COLOR.RED,
  },
  {
    status: "Cancelled",
    color: INDICATOR_COLOR.GRAY,
  },
  {
    status: "Errors",
    color: INDICATOR_COLOR.RED,
  },
  {
    status: "Mixed",
    color: INDICATOR_COLOR.YELLOW,
  },
  {
    status: "Request in process",
    color: INDICATOR_COLOR.WHITE,
  },
];

export const STEPPER_ORIENTATION = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
};

export const PAYMENT_HANDLER_TYPE = {
  ADD_CARD: 1,
  SASS_BILLING: 2,
};

export const PAYMENT_SECTION_TYPE = {
  LEGACY: 1,
  NEW_REACT: 2,
};

export const BookingFilePaymentStatus = {
  Unpaid: 0,
  PaymentDue: 1,
  Paid: 2,
  Overpaid: 3,
  Unknown: 255,
};

export const PAYMENT_STATUS = {
  Requested: 0,
  Completed: 1,
  Failed: 3,
  Errors: 4,
  Cancelled: 5,
  PendingSettlement: 6,
  Authorized: 7,
  PreAuthorized: 8,
};

export const SHOW_TICKET_UTILIZED_TOAST = "showTicketUtilizedToast";
export const IS_EMAIL_VERIFY = "isEmailVerify";

export const AIR_TRIP_TYPE = {
  OneWay: 0,
  RoundTrip: 1,
  Circle: 2,
  OpenJaw: 3,
  MultiCity: 4,
};

export const AIR_CABIN_CLASS = {
  0: "Any",
  1: "Economy",
  2: "Premium economy",
  3: "Business",
  4: "First",
};

export const SERVICE_TYPE_IDS = {
  INTERNATIONAL_TRANSFER: 1,
  INTERNATIONAL_ACTIVITY: 2,
  VISA_PROCESSING: 3,
  DISCOUNT: 4,
  REFUND: 5,
  CHANGE_PROCESSING: 6,
  CANCELLATION_PROCESSING: 7,
  INTERNATIONAL_SERVICE: 8,
  EVENT: 9,
  INTERNATIONAL_INSURANCE: 10,
  INTERNATIONAL_MEET_AND_GREET: 11,
  OFFER: 12,
  INTERNATIONAL_FLIGHT: 13,
  INTERNATIONAL_HOTEL_NET_SUPPLIER: 14,
  DOMESTIC_FLIGHT: 15,
  INTERNATIONAL_PACKAGE: 16,
  INTERNATIONAL_TRAIN: 17,
  BUS: 18,
  CONVENIENCE_FEE: 19,
  INTERNATIONAL_HOTEL_GROSS_SUPPLIER: 20,
  DONATION: 23,
  PROCESSING_FEE: 24,
  INTERNATIONALBAGGAGE: 25,
  DOMESTIC_ACTIVITY: 26,
  DOMESTIC_HOTEL_NET_SUPPLIER: 27,
  DOMESTIC_PACKAGE: 28,
  DOMESTIC_SERVICE: 29,
  DOMESTIC_TRANSFER: 30,
  DOMESTIC_HOTEL_GROSS_SUPPLIER: 31,
  COURIER_CHARGES: 32,
  OK_TO_BOARD_CHARGES: 33,
  UAE_VISA_PROCESSING: 34,
  WORLD_WIDE_VISA_PROCESSING: 35,
  INTERNATIONAL_EVENT: 36,
  DOMESTIC_EVENT: 37,
  QATAR_VISA_PROCESSING: 38,
  INTERNATIONALSEAT: 39,
  INTERNATIONALMEAL: 40,
  DOMESTICSEAT: 41,
  DOMESTICMEAL: 42,
  DOMESTICBAGGAGE: 43,
  AIRPORT_TRANSFER: 44,
  DOMESTIC_TRAIN: 45,
  DOMESTIC_MEET_AND_GREET: 46,
  DOMESTIC_INSURANCE: 47,
  TRANSACTIONPROCESSINGFEE: 48,
  DATECHANGE_INTERNATIONALFLIGHT: 49,
  DATECHANGE_DOMESTICFLIGHT: 50,
  DATECHANGE_INTERNATIONALHOTEL: 51,
  DATECHANGE_DOMESTICHOTEL: 52
};

export const SERVICE_TYPE = {
  1: { label: "International transfer" },
  2: { label: "International activity" },
  3: { label: "Visa processing" },
  4: { label: "Discount" },
  5: { label: "Refund" },
  6: { label: "Change processing" },
  7: { label: "Cancellation processing" },
  8: { label: "International service" },
  9: { label: "Event" },
  10: { label: "International Insurance" },
  11: { label: "International Meet and greet" },
  12: { label: "Offer" },
  13: { label: "International flight" },
  14: {
    label: "Hotel",
  },
  15: { label: "Domestic flight" },
  16: { label: "International package" },
  17: { label: "International Train" },
  18: { label: "Bus" },
  19: { label: "Convenience fee" },
  20: {
    label: "Hotel",
  },
  23: { label: "Donation" },
  24: { label: "Processing fee" },
  25: { label: "International Baggage" },
  26: { label: "Domestic activity" },
  27: { label: "Hotel" },
  28: { label: "Domestic package" },
  29: { label: "Domestic service" },
  30: { label: "Domestic transfer" },
  31: { label: "Hotel" },
  32: { label: "Courier charges" },
  33: { label: "Ok to board charges" },
  34: { label: "UAE Visa processing" },
  35: { label: "World wide visa processing" },
  36: { label: "International event" },
  37: { label: "Domestic event" },
  38: { label: "Qatar Visa processing" },
  39: { label: "International Seat" },
  40: { label: "International Meal" },
  41: { label: "Domestic Seat" },
  42: { label: "Domestic Meal" },
  43: { label: "Domestic Baggage" },
  44: { label: "Airport Transfer" },
  45: { label: "Domestic Train" },
  46: { label: "Domestic Meet And Greet" },
  47: { label: "Domestic Insurance" },
  48: { label: "Transaction Processing Fee" },
  49: { label: "Date Change - International Flight" },
  50: { label: "Date Change - Domestic Flight" },
  51: { label: "Date Change - International Hotel" },
  52: { label: "Date Change - Domestic Hotel" },
};

export const SORT_ORDER = {
  ASC: 1,
  DESC: -1,
};

export const DIALOG_WIDTHS = {
  EXTRA_SMALL: "xs",
  SMALL: "sm",
  MEDIUM: "md",
  LARGE: "xl",
};

export const TRAVELLER_TYPE = {
  ADULT: 1,
  CHILD: 2,
  INFANT: 3,
};

export const USER_TYPE = {
  BUSINESS: "business",
  MUSAFIR: "musafir",
};

export const APP_COLOR = {
  BUSINESS: "#004499",
  MUSAFIR: "#1199EE",
};

// # Html theme tags i.e link & meta
export const HTML_LINK_TAG = {
  "shortcut icon": {
    [USER_TYPE.BUSINESS]: [
      {
        href: "/app/assets/images/favicon-new/favicon.ico",
      },
    ],
    [USER_TYPE.MUSAFIR]: [
      {
        href: "/app/assets/images/favicon/favicon.ico",
      },
    ],
  },
  icon: {
    [USER_TYPE.BUSINESS]: [
      {
        sizes: "16x16",
        href: "/app/assets/images/favicon-new/favicon-16x16.png",
        type: "image/png",
      },
      {
        sizes: "32x32",
        href: "app/assets/images/favicon-new/favicon-32x32.png",
        type: "image/png",
      },
      {
        sizes: "192x192",
        href: "/app/assets/images/favicon-new/android-192.png",
        type: "image/png",
      },
      {
        sizes: "512x512",
        href: "/app/assets/images/favicon-new/android-512.png",
        type: "image/png",
      },
    ],
    [USER_TYPE.MUSAFIR]: [
      {
        sizes: "16x16",
        href: "/app/assets/images/favicon/favicon-16x16.png",
        type: "image/png",
      },
      {
        sizes: "32x32",
        href: "/app/assets/images/favicon/favicon-32x32.png",
        type: "image/png",
      },
      {
        sizes: "192x192",
        href: "/app/assets/images/favicon/android-192.png",
        type: "image/png",
      },
      {
        sizes: "512x512",
        href: "/app/assets/images/favicon/android-512.png",
        type: "image/png",
      },
    ],
  },

  "apple-touch-icon": {
    [USER_TYPE.BUSINESS]: [
      {
        sizes: "16x16",
        href: "/app/assets/images/favicon-new/apple-touch-icon.png",
      },
      {
        sizes: "120x120",
        href: "/app/assets/images/favicon-new/120.png",
      },
      {
        sizes: "150x150",
        href: "/app/assets/images/favicon-new/150.png",
      },
      {
        sizes: "152x152",
        href: "/app/assets/images/favicon-new/152.png",
      },
      {
        sizes: "167x167",
        href: "/app/assets/images/favicon-new/167.png",
      },
      {
        sizes: "180x180",
        href: "/app/assets/images/favicon-new/180.png",
      },
      {
        sizes: "192x192",
        href: "/app/assets/images/favicon-new/192.png",
      },
      {
        sizes: "256x256",
        href: "/app/assets/images/favicon-new/256.png",
      },
      {
        sizes: "384x384",
        href: "/app/assets/images/favicon-new/384.png",
      },
      {
        sizes: "512x512",
        href: "/app/assets/images/favicon-new/512.png",
      },
    ],
    [USER_TYPE.MUSAFIR]: [
      {
        sizes: "16x16",
        href: "/app/assets/images/favicon/apple-touch-icon.png",
      },
      {
        sizes: "120x120",
        href: "/app/assets/images/favicon/120.png",
      },
      {
        sizes: "150x150",
        href: "/app/assets/images/favicon/150.png",
      },
      {
        sizes: "152x152",
        href: "/app/assets/images/favicon/152.png",
      },
      {
        sizes: "167x167",
        href: "/app/assets/images/favicon/167.png",
      },
      {
        sizes: "180x180",
        href: "/app/assets/images/favicon/180.png",
      },
      {
        sizes: "192x192",
        href: "/app/assets/images/favicon/192.png",
      },
      {
        sizes: "256x256",
        href: "/app/assets/images/favicon/256.png",
      },
      {
        sizes: "384x384",
        href: "/app/assets/images/favicon/384.png",
      },
      {
        sizes: "512x512",
        href: "/app/assets/images/favicon/512.png",
      },
    ],
  },
  "mask-icon": {
    [USER_TYPE.BUSINESS]: [
      {
        color: APP_COLOR.BUSINESS,
        href: "/app/assets/images/favicon-new/safari-pinned-tab.svg",
      },
    ],
    [USER_TYPE.MUSAFIR]: [
      {
        color: APP_COLOR.MUSAFIR,
        href: "/app/assets/images/favicon/safari-pinned-tab.svg",
      },
    ],
  },
};

export const HTML_META_TAG = {
  "msapplication-square150x150logo": {
    business: {
      content: "/app/assets/images/favicon-new/favicon.ico",
    },
    musafir: {
      content: "/app/assets/images/favicon/favicon.ico",
    },
  },
  "msapplication-TileImage": {
    business: {
      content: "/app/assets/images/favicon-new/150x150.png",
    },
    musafir: {
      content: "/app/assets/images/favicon/150x150.png",
    },
  },
  "msapplication-TileColor": {
    business: {
      content: APP_COLOR.BUSINESS,
    },
    musafir: {
      content: APP_COLOR.MUSAFIR,
    },
  },
  "theme-color": {
    business: {
      content: APP_COLOR.BUSINESS,
    },
    musafir: {
      content: APP_COLOR.MUSAFIR,
    },
  },
  "msapplication-navbutton-color": {
    business: {
      content: APP_COLOR.BUSINESS,
    },
    musafir: {
      content: APP_COLOR.MUSAFIR,
    },
  },
  "apple-mobile-web-app-status-bar-style": {
    business: {
      content: "#black-translucent",
    },
    musafir: {
      content: "#black-translucent",
    },
  },
  "apple-mobile-web-app-title": {
    business: {
      content: "Business",
    },
    musafir: {
      content: "Musafir",
    },
  },
};
// end region

// #region Chat

export const CONTEXT_TYPE = {
  request: 1,
  trip: 2,
  approval: 3,
};

export const CONTEXT_TYPE_TEXT = {
  1: "Request",
  2: "Trip",
  3: "Approval",
};

export const CONVO_MESSAGE_TYPE = {
  CHAT: "1",
  REQUEST_SUMMARY: "2",
};

export const CONVO_MESSAGE_TYPE_TEXT = {
  1: "Chat",
  2: "Request",
};

// #endregion

export const MAX_TAG_VALUES_LIMIT = 1000;
