import ACTION_TYPES from "./appActionTypes";

import { initApp, getOrganizationDetails } from "./api/app";

import { retry } from "./utils/componentUtil";

export function initializeApplication(authenticationTokens, isdashboardcard) {
  return (dispatch) => {
    retry(() => initApp(authenticationTokens, isdashboardcard), 3)
      .then((response) => {
        dispatch({
          type: ACTION_TYPES.INITIALIZE_APP,
          payload: response,
        });
        try {
          if (typeof clarity !== "undefined") {
            const userID = response?.loggedInUser?.iD;
            const userName = response?.loggedInUser?.userName;
            if (userID && userName) {
              clarity("set", "userID", userID);
              clarity("set", "userName", userName);
            }
          }
        } catch (error) {}
      })
      .catch((err) => {
        dispatch({
          type: ACTION_TYPES.API_ERROR,
          payload: err.statusText,
        });
      });
  };
}

export function setPublicPageState(isPublicPage) {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_PUBLIC_PAGE,
      payload: isPublicPage,
    });
  };
}

export function setUnauthPageState(isUnAuth) {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_UNAUTH_STATE,
      payload: isUnAuth,
    });
  };
}

export function setIsMyUser(isMyUser) {
  return {
    type: ACTION_TYPES.SET_IS_MY_USER,
    payload: isMyUser,
  };
}

export function setAppTheme(appTheme) {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_APP_THEME,
      payload: appTheme,
    });
  };
}

export function changeLanguage(lang) {
  return {
    type: ACTION_TYPES.CHANGE_LANGUAGE,
    payload: lang,
  };
}

export function authenticateUser() {
  return { type: ACTION_TYPES.AUTHENTICATE_USER, payload: true };
}

export function setUser(user) {
  return { type: ACTION_TYPES.SET_USER, payload: user };
}

export function changeOnlineStatus() {
  return { type: ACTION_TYPES.UPDATE_ONLINE_STATUS };
}

export function setWindowSize(size) {
  return {
    type: ACTION_TYPES.SET_WINDOW_SIZE,
    payload: size,
  };
}

export function resetUser() {
  return {
    type: ACTION_TYPES.RESET_USER,
  };
}

export function setTransitionState(state) {
  return {
    type: ACTION_TYPES.SET_TRANSITION,
    payload: state,
  };
}

export function setIsAccountSuspended(state) {
  return {
    type: ACTION_TYPES.SET_IS_ACCOUNT_SUSPENDED,
    payload: state,
  };
}

export function setIsOneTimeUser(state) {
  return {
    type: ACTION_TYPES.SET_IS_ONE_TIME_USER,
    payload: state,
  };
}

export function setOrganisationSpecificDetails(details) {
  return {
    type: ACTION_TYPES.SET_ORGANISATION_SPECIFIC_DETAILS,
    payload: details,
  };
}

export function setCreditDetails(creditLimit) {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_CREDIT_LIMIT_DETAILS,
      payload: creditLimit,
    });
  };
}

export function setCabinClass(airCabinClasses) {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_CABIN_CLASS,
      payload: airCabinClasses,
    });
  };
}

export function setIsResellerBooking(isResellerBooking) {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SET_IS_RESELLER_BOOKING,
      payload: isResellerBooking,
    });
  };
}

// Fetch required organization details
export const fetchOrganizationDetails = (organizationId, data) => {
  return (dispatch) => {
    getOrganizationDetails(organizationId, data)
      .then((response) => {
        // Set credit limit details
        if (response.creditLimit) {
          dispatch(setCreditDetails(response.creditLimit));
        } else dispatch(setCreditDetails({}));

        if (response.airCabinClasses) {
          dispatch(setCabinClass(response.airCabinClasses));
        } else dispatch(setCabinClass());

        // Set is reseller booking details
        dispatch(setIsResellerBooking(response.isReseller));
      })
      .catch((ex) => {
        console.log("error fetching organization details");
      });
  };
};
