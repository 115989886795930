import { getConfiguration } from "../utils/configurationUtil";

export const ADD = "Add";
export const ACTION = "Action";
export const ADD_APIS = "Add APIS";
export const ACCOUNT = "Account";
export const ALL = "All";
export const APPROVAL = "Approval";
export const APPROVALS = "Approvals";
export const APPLY = "Apply";
export const APIS = "Apis";
export const APPROVE = "Approve";
export const ADD_TRIP = "Add trip";
export const APPROVED = "Approved";
export const APPROVAL_PENDING = "Approval pending";

export const bag = "bag";
export const BOOKING = "Booking";
export const BILLING = "Billing";
export const BUSINESS = "Business";

export const CABIN = "Cabin";
export const CANCEL = "Cancel";
export const CHANGE = "Change";
export const CHARGABLE = "Chargeable";
export const CHECK_IN = "Check-in";
export const CHECK_OUT = "Check-out";
export const CHANGE_PASSWORD_PAGE_TITLE = "Change password";
export const CONFIRM = "Confirm";
export const COPY = "Copy";
export const CUSTOMER = "Customer";
export const CLEAR = "Clear";
export const CREDIT = "Credit";
export const CREATED_DATE = "Created date";
export const CONSOLE = "Console";
export const CONTACT_NUMBER = "Contact number";
export const CONTINUE = "Continue";
export const CONVERSATION = "Conversation";

export const DATE_FORMAT_PLACEHOLDER = "DD/MM/YYYY";
export const DEFAULT_USER_TYPE = "B2C";
export const DELETE = "Delete";
export const DELETE_TRAVELLER_TITLE = "Delete this traveller?";
export const DELETE_TRAVELLER_MESSAGE =
  "Would you like to delete [TRAVELLER_NAME]? This action cannot be undone.";
export const DELETE_POLICY_TITLE = "Delete this policy?";
export const DELETE_POLICY_MESSAGE =
  "Would you like to delete [POLICY_NAME]? This action cannot be undone.";
export const DELETE_OFFLINE_REQUEST_DRAFT_TITLE =
  "Delete the offline request draft?";
export const DELETE_OFFLINE_REQUEST_DRAFT_MESSAGE =
  "Would you like to delete [DRAFT_TITLE]? This action cannot be undone.";
export const DELETE_EXPENSE_DRAFT_TITLE = "Delete the expense draft?";
export const DELETE_EXPENSE_DRAFT_MESSAGE =
  "Would you like to delete [DRAFT_TITLE]? This action cannot be undone.";
export const DEPARTMENT = "Department";
export const DETAILS = "Details";
export const DISCARD = "Discard";
export const DRAFT = "Draft";
export const DOWNLOAD = "Download";
export const DOCUMENTS = "Documents";

export const EDIT = "Edit";
export const EMAIL = "Email";
export const ENTITY = "Entity";
export const ENABLE = "Enable";
export const ENABLE_POLICY_TITLE = "Enable this policy?";
export const ENABLE_POLICY_MESSAGE =
  "[POLICY_NAME] expired on [DATE]. Would you still like to enable it";
export const EXPORT = "Export";
export const EXPENSE_APPROVALS = "Expense Approvals";

export const FAILED = "Failed";
export const FARE = "Fare";
export const FIRST_NAME = "First Name";
export const FILTER = "Filter";
export const FORGOT_PASSWORD_DESCRIPTION =
  "Simply fill in your registered email address below and we'll provide instructions for you to reset your password";
export const FORGOT_PASSWORD_DOCUMENT_TITLE = "Forgot password";
export const FORGOT_PASSWORD_PAGE1_TITLE = "Forgot password";
export const FORGOT_PASSWORD_PAGE2_TITLE = "Check your email";
export const FORGOT_PASSWORD_PAGE2_SUBTITLE = "Didn't receive the email?";
export const FORGOT_PASSWORD_PAGE2_SUBTITLE_DESCRIPTION =
  "Check spam or bulk folders for a message coming from noreply@musafir.com";
export const FLIGHT = "Flight";
export const FROM = "From";
export const FOLIOS = "Folios";

export const HOTEL = "Hotel";

export const ID = "Id";
export const ITINERARY = "Itinerary";
export const IMPORT = "Import";
export const INITIATE_APPROVAL = "Initiate approval";
export const INVITE_DOCUMENT_TITLE = MUSAFIR + "- Invite your workmates";
export const INVITE = "Invite your workmates";
export const INVOICE = "Invoice";

export const KEYWORD = "Keyword";
export const KEYWORD_SEARCH_BY_PROPERTY = "Search by property- keyword";

export const LEAVE_ON = "Leave on";
export const LOCATION = "Location";
export const LOCK = "Lock";
export const LOGIN_TITLE = "Login";
export const LOGOUT = "Logout";

export const meal = "meal";
export const MUSAFIR = "Musafir";
export const MULTIPLE = "Multiple";
export const MY = "My";

export const NAME = "Name";
export const NOTE = "Note";
export const NUMBER_OF_DAYS = "Number of days";

export const ORGANIZATION = "Organization";

export const POLICY = "Policy";
export const PAYMENT = "Payment";
export const PASSWORD = "Password";
export const PRICING = "Pricing";
export const PRODOCTION_MODE = "production";
export const PURCHASE = "Purchase";
export const PRICE_TO = "Price to";
export const PRICE_FROM = "Price from";
export const PRINT = "Print";

export const FIRST_LOGIN_TEXT =
  "Please update your Traveller Profile as per your passport information before you proceed further.";
export const RECEIPT = "Reciept";
export const REFUND = "Refund";
export const RESET_PASSWORD = "Reset password";
export const RETURN_TO_LOGIN = "Return ";
export const ROLE = "Role";
export const REQUEST = "Request";
export const REQUEST_REFUND = "Request refund";
export const RULE = "Rule";
export const REJECT = "Reject";
export const REQUESTS = "Requests";
export const REJECTED = "Rejected";
export const REIMBURESED = "Reimbursed";
export const REIMBURSE = "Reimburse";
export const REVERT = "Revert";

export const SAVE = "Save";
export const SAVE_DRAFT = "Save draft";
export const SEND_EMAIL = "Send Email";
export const SET_OFF = "Set off";
export const SERVICE = "Service";
export const SERVICES = "Services";
export const SHORTLISTED = "Shortlisted";
export const SHOW_PRICES = "Show prices";
export const SORT = "Sort";
export const SUBMIT_DOCUMENTS = "Submit documents";
export const SUBMIT = "Submit";
export const SUSPENDED_ACCOUNT = "Suspended Account";
export const SUBMITTED = "Submitted";
export const SEE_ROOMS = "See rooms";

export const TAG = "Tag";
export const TAGS_PAGE_TITLE = "Tags";
export const TICKET = "Ticket";
export const TO = "To";
export const TRAVELLER_DOCUMENT_TITLE = "Travellers";
export const TRAVELLER_PAGE_TITLE = "Travellers";
export const TRAVELLER = "Traveller";
export const TRIPS = "Trips";
export const TRIPS_PAGE_TITLE = "Trips";
export const TYPE = "Type";

export const UNLOCK = "Unlock";
export const UPDATE_STATUS = "Update Status";
export const USER = "User";
export const USER_RATING_FROM = "User rating from";
export const USERS = "Users";

export const VIEW = "View";
export const VIEW_USERS = "ViewUsers";

export const WORKFLOW = "Workflow";

export const MY_ACCOUNT = "My Account";
export const MY_ACCOUNT_PAGE_TITLE = "My Account";
export const MY_ACCOUNT_PAYMENT_PAGE_TITLE = "Payment";
export const MY_ACCOUNT_PAYMENT_PLANS_PAGE_TITLE = "Plans and payment";
export const MY_COMPANY_PAGE_TITLE = "My company";

export const OK = "Ok";
export const POLICIES_DOCUMENT_TITLE = "Policy";
export const POLICIES_PAGE_TITLE = "Policies";

export const APPROVALS_PAGE_TITLE = "Approval";

export const SUCCESS_ADD_CARD = "Success! your card details have been saved";
export const NO_PAYMENTS_MESSAGE =
  "Save your card and make your next purchase easier.<br/> [AddCard] a card today!";
export const ADD_CARD_MESSAGE = "Add a new card";

export const COMPONENTS_PAGE_TITLE = "Components";
export const COMPONENTS_TIMELINE_TITLE = "Timeline";
export const COMPONENTS_TABS_PAGE_TITLE = "Tabs";
export const COMPONENTS_CANCELLATION_PAGE_TITLE = "Cancellation Components";
export const COMPONENTS_DRAWER_PAGE_TITLE = "Drawers";
export const COMPONENTS_APPROVAL_DONATION_PAGE_TITLE = "Approval Donation";
export const COMPONENTS_APPROVAL_TRAVELER_PAGE_TITLE = "Approval Traveler";
export const COMPONENTS_STEPPER_PAGE_TITLE = "Stepper";
export const COMPONENTS_CHANGE_TRIP_PAGE_TITLE = "Change trip";
export const COMPONENTS_TRIP_COMPONENTS_TITLE = "Trips components";
export const COMPONENTS_TRIP_SERVICE_BLOCK = "Trips service block";
export const COMPONENTS_HOTEL_LIST_BLOCK = "Hotel list";
export const COMPONENTS_HOTEL_PACKAGE_LIST_BLOCK = "Hotel package list";

export const HOTEL_FILTER_AMENITIES = "Amenities";
export const HOTEL_FILTER_STAR_RATING = "Star rating";

export const HOTEL_CARD_CLICK = "Hotel card";
export const HOTEL_CARD_CLICK_HOTEL_NAME = "Hotel name";
export const HOTEL_CARD_CLICK_HOTEL_IMAGE = "Hotel image";
export const CLICK_PRICE = "Price";
export const BOOK_BUTTON = "Book button";
export const HOTEL_OFFER_LABEL = "Offer";
export const HOTEL_ROOM_DETAILS = "Room details";
export const HOTEL_ROOM_IMAGES = "Room images";
export const HOTEL_SHORTLIST = "Shortlist";
export const ROOMS = "rooms";
export const OVERVIEW = "overview";
export const MAPS = "maps";
export const NDC_FILTER_KEY = "ndc"; 

export const REQUEST_FORM = "RequestForm";

// Request form section
export const REQUEST_NAME_PLACEHOLDER = "Ex: John’s trip to UK in Dec '20";
export const RESUME_EDIT_BUTTON_TEXT = "Resume editing";
export const REIMBURESEMENTS = "Reimbursements";

// Created time difference to display
export const SINGLE_DAY = "1 day ago";
export const MULTIPLE_DAYS = "{0} days ago";
export const SINGLE_HOUR = "1 hour ago";
export const MULTIPLE_HOURS = "{0} hours ago";
export const SINGLE_MINUTE = "1 minute ago";
export const MULTIPLE_MINUTE = "{0} minutes ago";
export const FEW_SECONDS = "Few seconds ago";

//Comment section
export const COMMENT_PLACEHOLDER = "Add your comment";

export const EXPENSE_MANAGEMENT = "ExpenseManagement";
export const WITHDRAWN = "Withdrawn";

export const EMAIL_VALIDATION_REGEX = new RegExp(
  getConfiguration("emailValidationRegex") ||
    "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,15})+$",
);
